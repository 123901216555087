import { useEffect, useRef } from 'react';
import LoginPage from './features/login/LoginPage';
import { Routes, Route, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { PrivateOutlet } from './utils/PrivateOutlet';
import ContractsPage from './features/contract/ContractsPage';
import {
  selectNotificationLogMessage,
  setNotificationLogMessage,
  selectNotificationLogTitle,
  selectNotificationToastStatus,
  selectNotificationToastDismiss,
  selectNotificationToastDuration,
  selectNotificationErrors,
} from './features/notification/notificationSlice';
import ContractsDetail from './features/contract/ContractsDetail';
import Contract from './features/contract/Contract';
import JobLoader from '@/features/job/JobLoader';
import Welcome from './features/welcome/Welcome';
import NewContract from './features/contract/NewContract';
import MarginRules from './features/margin/MarginRules';
import UserManagement from './features/user/UserManagement';
import WelcomeError from './features/welcome/WelcomeError';
import Profile from '@/features/user/Profile';
import NewUser from './features/user/NewUser';
import User from './features/user/User';
import FacilityMapping from '@/features/mapping/FacilityMapping';
import EntityLookup from '@/features/entity/EntityLookup';
import SpecialtyMapping from '@/features/mapping/SpecialtyMapping';
import FeatureManagement from '@/features/flags/FeatureManagement';
import { LOGIN_ERROR, MSTOKEN, TOKEN_RECEIVED } from './utils/constants';
import { selectLoginStatus, setLoginStatus, selectLoginToken, setLoginToken } from './features/login/loginSlice';
import { isArrayValue, logError, parseErrorForLogging } from '@/utils/helpers';
import EditUser from './features/user/EditUser';
import Certifications from './features/certifications/Certifications';
import CertificationsPage from './features/certifications/CertificationsPage';
import NewCertifications from './features/certifications/NewCertifications';
import Modalities from './features/modality/Modalities';
import ModalitiesPage from './features/modality/ModalitiesPage';
import DataDump from './features/dataDump/DataDump';
import DataDumpPage from './features/dataDump/DataDumpPage';
import JumpLinks from './features/links/JumpLinks';
import JumpLinksPage from './features/links/JumpLinksPage';
import JobClosing from './features/jobClosing/JobClosing';
import JobClosingRules from './features/jobClosing/JobClosingRules';
import { useLazyGetFeaturesQuery, useLazyGetEnumsQuery, useGetMeQuery } from '@/app/services/nucleus';
import { setUserData } from '@/features/user/userSlice.js';

function App() {
  const dispatch = useDispatch();
  const toast = useToast();
  const toastIdRef = useRef();
  const logMessage = useSelector(selectNotificationLogMessage);
  const errors = useSelector(selectNotificationErrors);
  const logTitle = useSelector(selectNotificationLogTitle);
  const toastStatus = useSelector(selectNotificationToastStatus);
  const toastDismiss = useSelector(selectNotificationToastDismiss);
  const toastDuration = useSelector(selectNotificationToastDuration);
  const loginStatus = useSelector(selectLoginStatus);
  const token = useSelector(selectLoginToken);

  const { data: user, isSuccess } = useGetMeQuery();
  const [getFeatures] = useLazyGetFeaturesQuery();
  const [getEnums] = useLazyGetEnumsQuery();

  useEffect(() => {
    if (token && token !== '' && loginStatus !== LOGIN_ERROR) {
      dispatch(setLoginStatus(TOKEN_RECEIVED));
      getFeatures()
        .unwrap()
        .catch((error) => {
          console.log(error);
        });
      getEnums()
        .unwrap()
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (isSuccess && user) {
      dispatch(setLoginToken(MSTOKEN));
      dispatch(
        setUserData({
          id: user?.id,
          roles: user?.roles,
          admin: user?.admin,
          superadmin: user?.superadmin,
          email: user?.email,
          name: `${user?.first_name} ${user?.last_name}`,
          username: user?.username,
        }),
      );
    }
  }, [isSuccess, user]);

  useEffect(() => {
    if (loginStatus === TOKEN_RECEIVED) {
      toast.closeAll();
    }
  }, [loginStatus]);

  const addToast = (msg) => {
    toastIdRef.current = toast({
      position: 'top',
      title: logTitle,
      description: msg,
      status: toastStatus,
      duration: toastDuration,
      isClosable: toastDismiss,
      onCloseComplete: () => {
        dispatch(setNotificationLogMessage({ msg: '' }));
      },
    });
  };
  // catch-all for any dispatched notifications
  useEffect(() => {
    if (logMessage) {
      addToast(logMessage);
    }
  }, [logMessage]);

  // catch-all for any dispatched errors
  useEffect(() => {
    if (isArrayValue(errors)) {
      const result = parseErrorForLogging(errors);
      logError(result);
    }
  }, [errors]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/job_loader"
        element={
          <PrivateOutlet>
            <JobLoader />
          </PrivateOutlet>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateOutlet>
            <Profile />
          </PrivateOutlet>
        }
      />
      <Route
        path="/entity_lookup"
        element={
          <PrivateOutlet>
            <EntityLookup />
          </PrivateOutlet>
        }
      />
      <Route
        path="/facility_mapping"
        element={
          <PrivateOutlet>
            <FacilityMapping />
          </PrivateOutlet>
        }
      />
      <Route
        path="/specialty_mapping"
        element={
          <PrivateOutlet>
            <SpecialtyMapping />
          </PrivateOutlet>
        }
      />
      <Route
        path="/jump_links"
        element={
          <PrivateOutlet>
            <JumpLinks />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <JumpLinksPage />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path="/certifications"
        element={
          <PrivateOutlet>
            <Certifications />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <CertificationsPage />
            </PrivateOutlet>
          }
        />
        <Route
          path="/certifications/new"
          element={
            <PrivateOutlet>
              <NewCertifications />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path="/feature_management"
        element={
          <PrivateOutlet>
            <FeatureManagement />
          </PrivateOutlet>
        }
      />
      <Route
        path="/modalities"
        element={
          <PrivateOutlet>
            <Modalities />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <ModalitiesPage />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path="/data_dumps"
        element={
          <PrivateOutlet>
            <DataDump />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <DataDumpPage />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path="/margin_rules"
        element={
          <PrivateOutlet>
            <MarginRules />
          </PrivateOutlet>
        }
      />
      <Route
        path={`/user_management`}
        element={
          <PrivateOutlet>
            <User />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <UserManagement />
            </PrivateOutlet>
          }
        />
        <Route
          path="/user_management/new"
          element={
            <PrivateOutlet>
              <NewUser />
            </PrivateOutlet>
          }
        />
        <Route
          path="/user_management/:id"
          element={
            <PrivateOutlet>
              <EditUser />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path={`/job_closing_rules`}
        element={
          <PrivateOutlet>
            <JobClosing />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <JobClosingRules />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        index
        element={
          <PrivateOutlet>
            <Welcome />
          </PrivateOutlet>
        }
      />
      <Route
        path={`/contracts`}
        element={
          <PrivateOutlet>
            <Contract />
          </PrivateOutlet>
        }
      >
        <Route
          index
          element={
            <PrivateOutlet>
              <ContractsPage />
            </PrivateOutlet>
          }
        />
        <Route
          path="/contracts/:id/:active_tab?"
          element={
            <PrivateOutlet>
              <ContractsDetail />
            </PrivateOutlet>
          }
        />
        <Route
          path="/contracts/new"
          element={
            <PrivateOutlet>
              <NewContract />
            </PrivateOutlet>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <PrivateOutlet>
            <WelcomeError title="Not Found" desc="The page you requested does not exist." />
          </PrivateOutlet>
        }
      />
      <Route
        path="401"
        element={
          <PrivateOutlet>
            <WelcomeError title="Unauthorized" desc="You do not have access to the requested resource. Please contact an administrator." />
          </PrivateOutlet>
        }
      />
    </Routes>
  );
}

export default App;
